<template>
  <main class="video">
    <VideoBanner />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </main>
</template>

<script>
import VideoBanner from '@/components/Video/VideoBanner.vue'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style lang="scss"></style>
